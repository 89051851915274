import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="2015 Mensajes" />
      <h1>2015 Mensajes:</h1>
      <p>
        Abajo están los mensajes de los domingos del 2015, en una lista ordenada
        cronológicamente. Para DESCARGAR un mensaje haga CLIC DERECHO en enlace de
        descarga, y seleccione “Salvar destino como...” (Save Target As...) o “Salvar
        enlace como...” (Save Link como...), dependiendo del navegador que use. Para
        ESCUCHAR, sólo haga clic en el enlace “escuchar”. Para mayor ayudar, para escuchar
        y descargar archivos de audio, visite nuestra Página de Ayuda para Audio.{' '}
      </p>
      <h2>2015 Mensajes</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>15/05/15</td>
            <td>Grace Montero</td>
            <td>Rey, Reino, Reinado - parte 1</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/150515_King_Kingdom_Reign_1.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/150515_Rey_Reino_Reinado.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>16/15/15</td>
            <td>Grace Montero</td>
            <td>Rey, Reino, Reinado - parte 2</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/150515_King_Kingdom_Reign_2.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/150515_Rey_Reino_Reinado.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/07/15</td>
            <td>Jason Henderson</td>
            <td>La Gran Apostasía - parte 1</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/mp3/150722_Apostasia_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/01_Apostasia.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/07/15</td>
            <td>Jason Henderson</td>
            <td>La Gran Apostasía - parte 2</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/mp3/150722_Apostasia_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/02_Apostasia.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/07/15</td>
            <td>Jason Henderson</td>
            <td>La Gran Apostasía - parte 3</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/mp3/150722_Apostasia_03.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/03_Apostasia.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/07/15</td>
            <td>Jason Henderson</td>
            <td>La Gran Apostasía - parte 4</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/mp3/150722_Apostasia_04.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/04_Apostasia.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>14/10/15</td>
            <td>Jason Henderson</td>
            <td>El Reino de Dios - parte 1</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/mp3/151015_Reino_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/Reino1.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>14/10/15</td>
            <td>Jason Henderson</td>
            <td>El Reino de Dios - parte 2</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/mp3/151015_Reino_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/Reino2.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>14/10/15</td>
            <td>Jason Henderson</td>
            <td>El Reino de Dios - parte 3</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/mp3/151015_Reino_03.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/Reino3.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>14/10/15</td>
            <td>Jason Henderson</td>
            <td>El Reino de Dios - parte 4</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/mp3/151015_Reino_04.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/15/Reino4.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="59">14/10/15</td>
            <td width="139">Jason Henderson</td>
            <td width="376">El Reino de Dios - parte 5</td>
            <td width="41">
              <div align="center">
                <a href={asset('mensajes/15/mp3/151015_Reino_05.mp3')}>audio</a>
              </div>
            </td>
            <td width="39">
              <div align="center">
                <a href={asset('mensajes/15/Reino5.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
